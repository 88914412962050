import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import alerts from "./modules/alerts"
// import user from "./modules/userModule"
import admin from "./modules/adminModule"
import logger from "@/plugins/logger";
import createPersistedState from 'vuex-persistedstate'//새로고침 데이터 유지용 추가 

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [//새로고침 데이터 유지용 추가 
    createPersistedState()
  ],
  modules: {
    alerts,
    //user,
    admin
  },
  state: {
    pageName: "none",
    subName: "none",
    sessionToken: null,
    toggleNavStatus: true, 
  },
  getters: {
    getSessionToken: (state) =>{
      return state.sessionToken
    },
    pageName: (state) => {
      return state.pageName
    },
    subName: (state) => {
      return state.subName
    },
    toggleNavStatus: (state) => {
      return state.toggleNavStatus
    },
   
  },
  mutations: {
    mainPath (state, pageName) {
      state.pageName = pageName
    },
    subPath (state, subName) {
      state.subName = subName
    },
    sessionToken ( state,token){
      state.sessionToken = token;
    },
    toggleNavMutation (state) {
      console.log('전',state.toggleNavStatus)
      state.toggleNavStatus = !state.toggleNavStatus
      console.log('후',state.toggleNavStatus)
    }
  },
  actions: {
    async setSessionToken(context,{token}){
      context.commit('sessionToken',token);
    },
    async goMainPage (context, { pageName, subPage }) {
      logger.debug(pageName, subPage);

      context.commit('mainPath', pageName);
      if (subPage) {
        let before = this.getters.subName;

        if(before && before !== subPage) {
          context.commit('subPath', subPage);
          await router.push({ name: pageName, params: { subPage: subPage } })
        }
        else{
          logger.error("duplicate location!");
        }
      } else {
        context.commit('subPath', 'none');
        await router.push({ name: pageName })
      }
    },
    toggleNav(context){
      context.commit('toggleNavMutation');
    }
  }
})
