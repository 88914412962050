<template>
  <transition name="modal">
    <div class="pop_bg">
          <div class="pop pop_sm pop_group_set">
          <div class="pop_head">
            <slot name="head" />
          </div>
          <div class="pop_body">
            <slot name="body" />
          </div>
          <div class="pop_footer">
            <a class="btL-md" @click="closeModal()">{{ closeName }}</a>
            <a class="bt-md" @click="actionModal()">{{ actionName }}</a>
          </div>
        </div>
      </div>
  </transition>
</template>

<script>
import ModalHandler from "@/classes/ModalHandler";

export default {
  name: "ActionPopup",
  props: {
    title: { type: String },
    dialog: { type: ModalHandler },
    actionName: { type: String },
    closeName: { type: String },
  },
  data: () => ({}),
  computed: {},
  methods: {
    closeModal () {
     this.$emit('close');
    },
    actionModal () {
      console.log("modal - action");
      this.$emit('action');
    }
  },
};
</script>

