import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutCommon from '@/views/layout/anonymous/Layout'
import LayoutMember from '@/views/layout/member/Layout'
// import LayoutAdmin from '@/views/layout/admin/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Intro',
    redirect: { name: 'Login' }
  },
  {
    path: '/',
    component: LayoutCommon,
    children: [

      {
        path: '/login',
        component: () => import('../views/login/Login'),
        name: 'Login',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },

    ]
  },
  // {
  //   path: '/admin',
  //   component: LayoutAdmin,
  //   children: [
  //     {
  //       path: '/admin/dashboard',
  //       component: () => import('../views/admin/DashBoard'),
  //       name: 'AdminDashBoard',
  //       meta: {
  //         title: 'Ssantalk Admin Dashboard', icon: 'mdi-view-dashboard', noCache: true, affix: true
  //       }
  //     },
  //     {
  //       path: '/admin/send-entry-manage',
  //       component: () => import('../views/admin/SmsSendEntryManage'),
  //       name: 'AdminSendEntryManage',
  //       meta: {
  //         title: 'Ssantalk Admin Dashboard', icon: 'mdi-view-dashboard', noCache: true, affix: true
  //       }
  //     },
  //   ]
  // },
  {
    path: '/user',
    component: LayoutMember,
    children: [

      {
        path: '/dashboard',
        component: () => import('../views/member/DashBoard'),
        name: 'MemberDashboard',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/information-user',
        component: () => import('../views/member/InformationUserInfo'),
        name: 'InformationUserInfo',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/information-partner',
        component: () => import('../views/member/InformationPartnerInfo'),
        name: 'InformationPartnerInfo',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/information-user-detail',
        component: () => import('../views/member/InformationUserInfoDetail'),
        name: 'InformationUserInfoDetail',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
            {
        path: '/information-partner-detail',
        component: () => import('../views/member/InformationPartnerInfoDetail'),
        name: 'InformationPartnerInfoDetail',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/information-charge-history',
        component: () => import('../views/member/InformationChargeHistory'),
        name: 'InformationChargeHistory',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/information-point-history',
        component: () => import('../views/member/InformationPointHistory'),
        name: 'InformationPointHistory',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },

      {
        path: '/information-send',
        component: () => import('../views/member/InformationSend'),
        name: 'InformationSend',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/report-partner',
        component: () => import('../views/member/InformationReportPartner'),
        name: 'InformationReportPartner',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/information-sales',
        component: () => import('../views/member/InformationSales'),
        name: 'InformationSales',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/information-charge',
        component: () => import('../views/member/InformationChargeStatus'),
        name: 'InformationChargeStatus',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/request-send-check',
        component: () => import('../views/member/RequestSendCheck'),
        name: 'RequestSendCheck',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/request-send-check-mobile',
        component: () => import('../views/member/RequestSendCheckMobile'),
        name: 'RequestSendCheckMobile',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/request-transform',
        component: () => import('../views/member/RequestTransform'),
        name: 'RequestTransform',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/request-partner-transform',
        component: () => import('../views/member/RequestPartnerTransform'),
        name: 'RequestPartnerTransform',
        meta: {
          title: 'API', icon: 'mdi-swap-horizontal', noCache: true, affix: true
        }
      },
      {
        path: '/request-send-number',
        component: () => import('../views/member/RequestSendNumber'),
        name: 'RequestSendNumber',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/request-refund',
        component: () => import('../views/member/RequestRefund'),
        name: 'RequestRefund',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/request-partner-payment',
        component: () => import('../views/member/RequestPartnerPayment'),
        name: 'RequestPartnerPayment',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/request-qna',
        component: () => import('../views/member/RequestQna'),
        name: 'RequestQna',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/request-qna-detail',
        component: () => import('../views/member/RequestQnaDetail'),
        name: 'RequestQnaDetail',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/operation-notice',
        component: () => import('../views/member/OperationNotice'),
        name: 'OperationNotice',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/operation-notice-detail',
        component: () => import('../views/member/OperationNoticeDetail'),
        name: 'OperationNoticeDetail',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/operation-notice-add',
        component: () => import('../views/member/OperationNoticeAdd'),
        name: 'OperationNoticeAdd',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/operation-notice-update',
        component: () => import('../views/member/OperationNoticeUpdate'),
        name: 'OperationNoticeUpdate',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/operation-help',
        component: () => import('../views/member/OperationHelp'),
        name: 'OperationHelp',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/operation-help-detail',
        component: () => import('../views/member/OperationHelpDetail'),
        name: 'OperationHelpDetail',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/operation-help-add',
        component: () => import('../views/member/OperationHelpAdd'),
        name: 'OperationHelpAdd',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/operation-help-update',
        component: () => import('../views/member/OperationHelpUpdate'),
        name: 'OperationHelpUpdate',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/operation-popup-update',
        component: () => import('../views/member/OperationPopupUpdate'),
        name: 'OperationPopupUpdate',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: '/charge_log',
        component: () => import('../views/member/ChargeLog'),
        name: 'ChargeLog',
        meta: {
          title: 'API', icon: 'mdi-cellphone-dock', noCache: true, affix: true
        }
      },
      {
        path: "/404",
        component: () => import('../views/member/NotFound'),
        name: "NotFound",
      },
      {
        path: '/*',
        redirect: { name: 'NotFound' },
      },
      // {
      //   path: '/payment',
      //   component: () => import('../views/member/MemberPayment'),
      //   name: 'MemberPayment',
      //   meta: {
      //     title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
      //   }
      // },
      // {
      //   path: '/send-report',
      //   component: () => import('../views/member/MemberSendReport'),
      //   name: 'MemberSendReport',
      //   meta: {
      //     title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
      //   }
      // },
      // {
      //   path: '/member-edit-info',
      //   component: () => import('../views/member/MemberEditInfo'),
      //   name: 'MemberEditInfo',
      //   props : true,
      //   meta: {
      //     title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
      //   }
      // },
      // {
      //   path: '/member-withraw-done',
      //   component: () => import('../views/member/MemberWithdrawDone'),
      //   name: 'MemberWithdrawDone',
      //   props : true,
      //   meta: {
      //     title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
      //   }
      // },
      // {
      //   path: '/member-announce-info',
      //   component: () => import('../views/member/MemberAnnounce'),
      //   name: 'MemberAnnounce',
      //   props : true,
      //   meta: {
      //     title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
      //   }
      // }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
