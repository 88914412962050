import Vue from 'vue'
import axios from 'axios';
import logger from "@/plugins/logger";
import mock from "@/plugins/mock";
import store from "@/store";
import {AUTH_SESSION_STORAGE, AUTH_TOKEN_NAME} from "@/constants";
import router from '@/router/index';

const service = axios.create({
  baseURL: process.env.NODE_ENV !== 'production' ? '':process.env.VUE_APP_API_URL, // url = base url + request url
  timeout: 30000,
});
Vue.prototype.$axios = service

// Request interceptors
service.interceptors.request.use(
  (config) => {
    store.dispatch('showLoading').then();

    // Add X-Access-Token header to every request, you can add other custom headers here
    let token = store.getters.getSessionToken;

    if (!token) {
      const content = localStorage.getItem(AUTH_SESSION_STORAGE);

      if (content) {
        token = content;

        logger.debug("login page token:" + token);

        if (token) {
          store.dispatch("setSessionToken", content).then();
        }
      }
    }

    logger.debug("user-token", token);

    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
      config.headers[AUTH_TOKEN_NAME] = token;
    }

    logger.debug("request-config", config);

    return config;
  },
  (error) => {
    logger.debug("onRejected", error);
    return Promise.reject(error);
  },
);

service.interceptors.response.use((response) => {
  store.dispatch('hideLoading').then()

  logger.debug('response', response);

  return response;
}, (error) => {
  logger.debug('error', error.response.status)
  store.dispatch('hideLoading').then();
  //console.debug('logOut', error)
  let errorMsg = '';
  let token = store.getters.getSessionToken;

  if(router.currentRoute.name !== "Login") {
    if (token == null) { //세션 없는 상태에서 접속될때 
      router.replace({name: 'Login'})
      alert('요청 하신 서비스에 대한 권한이 없습니다.')
      store.commit('sessionToken', null);
      return error
    }
  }
  if(error.response.status == 403) {
    logger.log('!!!!!!!!!!!error', error);

    return null
  }

  if (error.response && error.response.status === 401 && error.response.data.detail == 'Bad credentials') {
    errorMsg = '인증되지 않았습니다.';
  } else if (error.response && (error.response.status === 401)) {
    errorMsg = '인증되지 않았습니다.';
    window.location.href = "/#/SingIn";
  } else if (error.response && (error.response.status === 403)) {
    errorMsg = '요청 하신 서비스에 대한 권한이 없습니다. 관리자에게 문의하십시오.';
  } else if (error.response && (error.response.status === 500) && error.response.data.includes("ECONNREFUSED")) {
    errorMsg = '요청 하신 서비스가 응답이 없습니다.';
  } else {
    errorMsg = '처리 중 오류가 발생하였습니다. 관리자에게 문의하십시오.';
  }

  //에러 alert 추가
  store.dispatch('addAlert', {
    'type': 'error',
    'message': errorMsg,
  }).then();

  return Promise.reject(error);
});

export default async ($uri, $method, $payload, $header) => {
  if (process.env.VUE_APP_DATA_MODE === 'mock') {
    const mockValue = mock.getMock($uri);

    if (mockValue) {
      return mockValue;
    }
  }

  logger.debug(`[${$uri}:${$method}] proxy with : ${JSON.stringify($payload)}`);

  const {data} = await service({
    url: $uri,
    method: $method,
    data: $payload,
    headers: $header
  });

  logger.debug(data);

  return data;
};
