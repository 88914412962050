<template>
  <!-- header -->
  <div class="header">
    <div class="nav-header">
      <button class="mobile_hamburger ion-grid" @click="toggleNav" v-if="!toggleNavStatus()"></button>
      <div class="brand-logo">
        <router-link to="dashboard">
          <b><img src="admin_asset/images/logo.png" alt=""> </b><span class="brand-title">
            <img src="admin_asset/images/logo-text.png" alt=""></span>
        </router-link>
      </div>
      <!--      <div class="nav-control">-->
      <!--        <div class="hamburger"><span class="line"></span> <span class="line"></span> <span class="line"></span>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="header-content">
      <div class="header-right">
        <ul>
          <li class="icons">
            <a href="#" @click="doAdminLogOut()"><i class="icon-power"></i> <span>Logout</span></a>
            <!-- <a href="javascript:void(0)" @click="toggleInfo"><i class="mdi mdi-account f-s-20" aria-hidden="true"></i>
            </a> -->
            <!-- <div class="drop-down dropdown-profile animated bounceInDown" id="dropdown">
              <div class="dropdown-content-body">
                <ul>
                  <li><a href="#"><i class="mdi mdi-email"></i> <span>Inbox</span></a>
                  </li>
                  <li><a href="#"><i class="mdi mdi-settings"></i> <span>Setting</span></a>
                  </li>
                  <li><a href="#"><i class="icon-lock"></i> <span>Lock Screen</span></a>
                  </li>
                  <li>
                  </li>
                </ul>
              </div>
            </div> -->
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- #/ header -->
</template>

<script>
  import {
    mapActions,
    mapGetters
  } from 'vuex'
  const js = require('@/assets/admin_common');
  export default {
    name: 'HeaderPage',
    mixins: [js.default],
    methods: {
      ...mapGetters(["pageName", "toggleNavStatus"]),
      ...mapActions(["goMainPage", "toggleNav"]),
      nav(name) {
        this.goMainPage({
          pageName: name
        });
      },
      toggleInfo() {
        document.getElementById('dropdown').style.display = 'block'
      },
      handleResize(){
        if(!this.toggleNavStatus() && window.innerWidth>768) {
          this.toggleNav()
        }
      },
     
    },
    mounted() {
      // console.log("ready...");
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      // console.log("beforeDestroy...");
      window.removeEventListener('resize', this.handleResize);
    },

  }

</script>
<style>
  .search_area i.v-icon{
    margin-left:auto
  }
  .total-count {
    position: absolute;
    right: 30px;
    margin-top: -30px
  }

  .v-date-picker-table button.v-btn.v-btn--active {
    background: #295FD3 !important;
  }

  pre {
    white-space: -moz-pre-wrap;
    /* Mozilla, supported since 1999 */
    white-space: -pre-wrap;
    /* Opera */
    white-space: -o-pre-wrap;
    /* Opera */
    white-space: pre-wrap;
    /* CSS3 - Text module (Candidate Recommendation) */
    word-wrap: break-word;
    /* IE 5.5+ */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  a {
    cursor: pointer;
    color: #4365ED;
  }

  .download-area {
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .download-area .select_box {
    margin-right: 5px;
    padding: 0 30px 0 15px;
    line-height: 31px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background: url(https://www.ssantalk.co.kr/images/ico_select_arrow.svg) no-repeat 95% 50% #fff;
  }

  button.v-btn.v-btn--active {
    background: #295FD3;
  }

  .theme--light.v-card {
    margin-top: 34px;
  }

  .modal-body img {
    max-width: 100%;
  }

  a {
    cursor: pointer;
    color: #4365ED;
  }

</style>
<style scoped>
  .nav-header {
    display: flex;
    vertical-align: middle;
  }

  .mobile_hamburger,
  .mobile_exit {
    display: none;
  }

  @media (max-width: 767px) {
    .nav-header {
      display: flex;
    }
    .brand-logo{
      margin:auto
    }

    .mobile_hamburger {
      display: inline-block;
      /* position:absolute;  */
      /* top:60px; */
      font-size: 24px;
      /* content: url('/images/logo_symbol.png'); */
      /* margin-top: 10px; */
      margin-left: 10px;
      z-index: 100000;
    }


  }

</style>
